.crossyn-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 25px;
    right: 70px;
}

.crossyn-container input {
    margin-right: 10px;
}

.crossyn-data {
    color: #fff;
    background-color: #29235c;
    border: none;
    padding: 8px 15px;
    cursor: pointer;
    border-radius: 3%;
    margin-left: 20px;
}

.crossyn-data-show {
    background-color: green;
}